import React, {Component} from "react"
import ApiRequest from "./../controller/apiRequest"
import "./AlarmForm.css"
import {Grid, TextField, Box} from '@mui/material'
import VehicleList from "./inputComponents/VehicleList";
import InputGroup from "./inputComponents/InputGroup";
import InputField from "./inputComponents/InputField";
import FireTruck from "@mui/icons-material/FireTruck";
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import Cookies from 'js-cookie';
import InteractionsBar from "./components/InteractionsBar";

type AlarmFormState = {
    short: string,
    keyword: string,
    caller: string,
    section: string,
    object: string,
    city: string,
    address: string,
    text: string,
    date: string,
    time: string,
    vehicleFire: [number,string,string][],
    vehicleOther: [number,string,string][],
}
export class AlarmForm extends Component<{}, AlarmFormState> {

    constructor(props: any) {
        super(props);
        if (Cookies.get("alarmgen_alarmform_state") === undefined) {
            this.state = {
                short: "",
                keyword: "",
                caller: "",
                section: "",
                object: "",
                city: "",
                address: "",
                text: "",
                date: "",
                time: "",
                vehicleFire: [],
                vehicleOther: []
            };
        }
        else {
            this.state = JSON.parse(Cookies.get("alarmgen_alarmform_state") as string);
        }
        this.generate = this.generate.bind(this);
    }

    generate() {
        ApiRequest.request(
            this.state.date,
            this.state.time,
            this.state.short,
            this.state.keyword,
            this.state.city,
            this.state.address,
            this.state.caller,
            this.state.text,
            this.state.vehicleFire.map(([,station,callsign]) => [station, callsign]),
            this.state.vehicleOther.map(([,station,callsign]) => [station, callsign]),
            this.state.section,
            this.state.object
        )
    }

    clearAll() {
        this.setState({
            short: "",
            keyword: "",
            caller: "",
            section: "",
            object: "",
            city: "",
            address: "",
            text: "",
            date: "",
            time: "",
            vehicleFire: [],
            vehicleOther: []
        });
    }

    componentDidUpdate( prevProps: any, prevState: AlarmFormState) {
        this.saveState(this.state);
    }

    saveState(state: AlarmFormState) {
        if (Cookies.get("alarmgen_allowcookies") !== undefined && Cookies.get("alarmgen_allowcookies") === "true") {
            Cookies.set("alarmgen_alarmform_state", JSON.stringify(state), { expires: 14, sameSite: 'lax' });
        }
    }

    handleShortChange(event: any) {
        this.setState({
            short: event.target.value
        });
    }

    handleKeywordChange(event: any) {
        this.setState({
            keyword: event.target.value
        });
    }

    handleCallerChange(event: any) {
        this.setState({
            caller: event.target.value
        });
    }

    handleSectionChange(event: any) {
        this.setState({
            section: event.target.value
        });
    }

    handleObjectChange(event: any) {
        this.setState({
            object: event.target.value
        });
    }

    handleTextChange(event: any) {
        this.setState({
            text: event.target.value
        });
    }

    handleCityChange(event: any) {
        this.setState({
            city: event.target.value
        });
    }

    handleAddressChange(event: any) {
        this.setState({
            address: event.target.value
        });
    }

    handleDateChange(event: any) {
        this.setState({
            date: event.target.value
        });
    }

    handleTimeChange(event: any) {
        this.setState({
            time: event.target.value
        });
    }

    render() {
        const _vehicleFire = {
            vehicle: this.state.vehicleFire,
        }
        const _vehicleOther = {
            vehicle: this.state.vehicleOther,
        }
        return <div className="AlarmForm">
            <Box sx={{

                display: {xs: 'none', md: 'block'},
                width: "100%"
            }}>
                <InteractionsBar generate={this.generate.bind(this)} clearAll={this.clearAll.bind(this)}/>
            </Box>
            <InputGroup title="Informationen zum Einsatz">
                <Grid container spacing={3}>
                    <Grid item>
                        <InputField
                            title="Alarmstufe"
                            max_size={20}
                            changeHandler={this.handleShortChange.bind(this)}
                            description="Die Alarmstufe des Einsatzes."
                            example="B3"
                            value={this.state.short}
                        />
                    </Grid>
                    <Grid item>
                        <InputField
                            title="Alarmstichwort"
                            max_size={40}
                            changeHandler={this.handleKeywordChange.bind(this)}
                            description="Das Alarmstichwort"
                            example="TH - Technische Hilfe klein"
                            value={this.state.keyword}
                        />
                    </Grid>
                    <Grid item>
                        <InputField
                            title="Mitteiler"
                            max_size={40}
                            changeHandler={this.handleCallerChange.bind(this)}
                            description="Der Mitteiler des Einsatzes"
                            value={this.state.caller}
                        />
                    </Grid>
                    <Grid item>
                        <TextField id="text-TF" onChange={this.handleTextChange.bind(this)} sx={{width: 300}}
                                   label="Freitext" rows={3} multiline variant="outlined"
                                   helperText={this.state.text.length.toString() + "/500"}
                                   value={this.state.text}
                                   inputProps={{
                                       maxLength: 500,
                                   }}/>
                        <div className="Description">Freitext zum Einsatz</div>
                    </Grid>
                </Grid>
            </InputGroup>
            <InputGroup title="Ort und Zeit">
                <Grid container spacing={3}>
                    <Grid item>
                        <InputField
                            title="Ortsteil/Gemeinde"
                            max_size={40}
                            changeHandler={this.handleCityChange.bind(this)}
                            description="Der Ort des Einsatzes"
                            example="Flehingen/Oberderdingen"
                            value={this.state.city}
                        />
                    </Grid>
                    <Grid item>
                        <InputField
                            title="Einsatzort"
                            max_size={40}
                            changeHandler={this.handleAddressChange.bind(this)}
                            description="Die Adresse des Einsatzes"
                            example="Hauptstraße 1"
                            value={this.state.address}
                        />
                    </Grid>
                    <Grid item>
                        <InputField
                            title="Datum"
                            max_size={10}
                            changeHandler={this.handleDateChange.bind(this)}
                            description="Das Datum"
                            example="23.07.2022"
                            value={this.state.date}
                        />
                    </Grid>
                    <Grid item>
                        <InputField
                            title="Uhrzeit"
                            max_size={5}
                            changeHandler={this.handleTimeChange.bind(this)}
                            description="Die Uhrzeit"
                            example="01:15"
                            value={this.state.time}
                        />
                    </Grid>
                </Grid>
            </InputGroup>
            <InputGroup title="Fahrzeuge Feuerwehr">
                <VehicleList {..._vehicleFire}
                             icon={FireTruck}
                             exampleCallsign="KÜ21"
                             exampleStation="FW OD 02 Flehingen"
                             description="Neues Fahrzeug der Feuerwehr hinzufügen"
                             updateMaster={this.saveState.bind(this, this.state)}
                />
            </InputGroup>
            <InputGroup title="Fahrzeuge anderer Organisationen">
                <VehicleList {..._vehicleOther}
                             icon={LocalHospitalIcon}
                             exampleCallsign="DRK 4/83-2"
                             exampleStation="RW DRK Oberderdingen"
                             description="Neues Fahrzeug anderer Organisationen hinzufügen"
                             updateMaster={this.saveState.bind(this, this.state)}
                />
            </InputGroup>
            <InputGroup title="Erweiterte Informationen (Optional">
                <Grid container spacing={3}>
                    <Grid item>
                        <InputField
                            title="Abschnitt"
                            max_size={40}
                            changeHandler={this.handleSectionChange.bind(this)}
                            description="Der Einsatzabschnitt"
                            value={this.state.section}
                        />
                    </Grid>
                    <Grid item>
                        <InputField
                            title="Objekt"
                            max_size={40}
                            changeHandler={this.handleObjectChange.bind(this)}
                            description="Das Einsatzobjekt"
                            value={this.state.object}
                        />
                    </Grid>
                </Grid>
            </InputGroup>
            <InteractionsBar generate={this.generate.bind(this)} clearAll={this.clearAll.bind(this)}/>
        </div>

    }
}