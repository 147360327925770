import React, {Component} from 'react';
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Cookies from 'js-cookie';
import './CookieNotice.css'

export default class CookieNotice extends Component<{}, {
    open: boolean
    detail: boolean
}> {
    constructor(props: any) {
        super(props);
        this.state = {
            open: Cookies.get("alarmgen_allowcookies") === undefined,
            detail: false
        };
    }
    close(allow: boolean) {
        this.setState({
            open: false
        });
        Cookies.set("alarmgen_allowcookies", String(allow), { expires: 365, sameSite: 'lax' });
    }

    setDetail(show: boolean) {
        this.setState({
            detail: show
        });
    }

    render() {
        return <div>
            <Dialog
                open={this.state.open}
                onClose={() => {}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Diese Webseite nutzt Cookies"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Diese Website verwendet Cookies – nähere Informationen dazu finden sie unter „Mehr Informationen zu unseren Cookies“.
                        Klicken Sie auf „Ich stimme zu“, um Cookies zu akzeptieren und direkt unsere Website besuchen zu können.
                        Wollen Sie nicht, dass Cookies auf dieser Website aktiviert werden drücken Sie auf „Ich lehne ab“
                    </DialogContentText>
                    <Button variant="text" onClick={this.setDetail.bind(this, true)}>
                        Mehr Informationen zu unseren Cookies
                    </Button>
                    <CookieNoticeInformation open={this.state.detail} close={this.setDetail.bind(this, false)}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.close.bind(this, false)} variant="contained">
                        Ich lehne ab
                    </Button>
                    <Button onClick={this.close.bind(this, true)} variant="contained">
                        ich stimme zu
                    </Button>
                </DialogActions>
            </Dialog>
        </div>;
    }
}

class CookieNoticeInformation extends Component<{
    open: boolean
    close: () => void
}, {}> {

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.close}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Mehr Informationen zu unseren Cookies"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContent id="alert-dialog-description" className="cookie-notice-content">
                            <h1>Was sind Cookies?</h1>
                            <p>
                                Cookies und ähnliche Technologien sind sehr kleine Textdokumente oder Codeteile, die oft einen eindeutigen Identifikationscode enthalten.
                                Wenn Sie eine Website besuchen oder eine mobile Anwendung verwenden, bittet ihr Browser Ihren Computer oder Ihr mobiles Gerät um die Erlaubnis, diese Datei auf Ihrem Computer oder mobilen Gerät zu speichern und Zugang zu Informationen zu erhalten.
                                Informationen, die durch Cookies und ähnliche Technologien gesammelt werden, können das Datum und die Uhrzeit des Besuchs sowie die Art und Weise, wie Sie eine bestimmte Website oder mobile Anwendung nutzen, beinhalten.
                            </p>
                            <h1>Warum verwenden wir Cookies?</h1>
                            <p>
                                Cookies sorgen dafür, dass Sie während Ihres Besuchs auf unserer Website des für Sie bestmögliche Erlebnis erleben.
                                Dafür speichern wir die letzten von Ihnen gemachten Eingaben.
                            </p>
                            <h1>Welche Art von Cookies verwenden wir?</h1>
                            <h2>Notwendige Cookies:</h2>
                            <p>
                                Diese Cookies sind für die funktion der Webseite notwendig.
                                Wir verwenden folgende Cookies:
                            </p>
                            <ul>
                                <li>
                                    Speichern Ihrer wahl zu den Cookie-Einstellungen
                                </li>
                            </ul>
                            <h2>Funktionelle Cookies:</h2>
                            <p>
                                Diese Cookies erweitern die Funktionen der Webseite.
                                Wir verwenden hier die folgenden Cookies:
                            </p>
                            <ul>
                                <li>
                                    Speichern Ihre letzten Eingabe.
                                    Dadurch bleiben die Textfelder auch bei einem neuladen der Webseite ausgefüllt.
                                </li>
                            </ul>
                        </DialogContent>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.close} variant="contained">
                            Schließen
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
