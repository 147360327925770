import React, {Component} from 'react';
import './AlarmGenerator.css';
import {AlarmForm} from "./view/AlarmForm";
import { createTheme, ThemeProvider} from '@mui/material/styles';
import CookieNotice from "./view/dialog/CookieNotice";
import Navbar from "./view/components/Navbar";

const theme = createTheme({
    palette: {
        primary: {
            main: '#b71c1c',
        },
        secondary: {
            main: '#ff6d00',
        },
    },
});

export default class AlarmGenerator extends Component<{}, {}> {

    render() {
        return<ThemeProvider theme={theme}>
                <div className="App">
                    <CookieNotice/>
                    <Navbar/>
                    <AlarmForm/>
                </div>
            </ThemeProvider>
    }
}
