import React, {Component} from 'react';
import './InputGroup.css'

type InputGroupProps = {
    title: string
    children?: JSX.Element | JSX.Element[]
}
type InputGroupState = {

}

export default class InputGroup extends Component<InputGroupProps, InputGroupState> {
    render() {
        return <div className="InputField">
            <h1>{this.props.title}</h1>
            {this.props.children}
        </div>;
    }
}