import React, {Component} from 'react';
import {
    Avatar,
    Button,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    SvgIcon,
    TextField
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import './VehicleList.css'

type VehicleListProps = {
    vehicle: [number, string, string][]
    icon: typeof SvgIcon
    exampleCallsign: string
    exampleStation: string
    description: string
    updateMaster: () => void
}
type VehicleListState = {
    station: string,
    callsign: string
}
export default class VehicleList extends Component<VehicleListProps, VehicleListState> {
    constructor(props: VehicleListProps | Readonly<VehicleListProps>) {
        super(props);
        this.state = {
            station: "",
            callsign: "",
        }
    }

    handleStationChange(event: any) {
        this.setState({
            station: event.target.value
        });
    }

    handleCallsignChange(event: any) {
        this.setState({
            callsign: event.target.value
        });
    }

    deleteVehicle(index: number) {
        this.props.vehicle.forEach(([x, station, callsign], i, a) => {
            if (index === x) {
                a.splice(i, 1)
            }
        });
        this.forceUpdate()
    }

    render() {
        let listVisible = this.props.vehicle.length !== 0
        return <Grid container spacing={3}>
            <Grid item style={{textAlign: "center"}}>
                <h2>{this.props.description}</h2>
                <Grid container spacing={3}>
                    <Grid item>
                        <TextField id="station1-TF" onChange={this.handleStationChange.bind(this)} sx={{width: 300}}
                                   label="Standort" variant="outlined"
                                   helperText={this.state.station.length.toString() + "/40"}
                                   inputProps={{
                                       maxLength: 40,
                                   }}/>
                        <div className="Description">
                            Der Fahrzeugstandort<br/>Zum Beispiel: {this.props.exampleStation}
                        </div>
                    </Grid>
                    <Grid item>
                        <TextField id="vehicle1-TF" onChange={this.handleCallsignChange.bind(this)} sx={{width: 300}}
                                   label="Fahrzeug" variant="outlined"
                                   helperText={this.state.callsign.length.toString() + "/40"}
                                   inputProps={{
                                       maxLength: 40,
                                   }}/>
                        <div className="Description">
                            Der Funkrufnahme des Fahrzeugs<br/>Zum Beispiel: {this.props.exampleCallsign}
                        </div>
                    </Grid>
                </Grid>
                <Button onClick={() => {
                    if (this.state.station !== "" && this.state.callsign !== "") {
                        const newIndex = (this.props.vehicle.length !== 0) ? (this.props.vehicle[this.props.vehicle.length - 1][0] + 1) : 1;
                        this.props.vehicle.push([newIndex, this.state.station, this.state.callsign]);
                        this.forceUpdate();
                        this.props.updateMaster();
                    } else {
                        alert("Fahrzeugdaten unvollständig");
                    }
                }} variant="outlined" sx={{mt: 2}}>
                    <p>Fahrzeug hinzufügen</p>
                </Button>
            </Grid>
            <Grid item>
                <List className={listVisible ? "vehicleList" : "invisibleList"} dense={true} sx={{width: 300}}>
                    {this.props.vehicle.map(([x, station, callsign]) => (
                        <ListItem
                            secondaryAction={
                                <IconButton edge="end" aria-label="delete"
                                            onClick={() => {
                                                this.deleteVehicle(x);
                                                this.props.updateMaster();
                                            }}>
                                    <DeleteIcon/>
                                </IconButton>
                            }
                        >
                            <ListItemAvatar>
                                <Avatar>
                                    <this.props.icon/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={callsign}
                                secondary={station}
                            />
                        </ListItem>
                    ))}
                </List>
            </Grid>

        </Grid>
    }
}