import React, {Component} from 'react';
import './InteractionsBar.css'
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import {Button, Grid, Box} from "@mui/material";

export default class InteractionsBar extends Component<{
    generate: () => void,
    clearAll: () => void
}, {}> {
    render() {
        return <div>
            <Grid
                container
                spacing={1}
            >
                <Grid item xs={12} md={4}>
                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <Grid container justifyContent="center">
                            <Button onClick={this.props.generate} variant="contained" color="secondary">
                                <DownloadForOfflineIcon />
                                <p className="button">Alarmdepesche generieren</p>
                            </Button>
                        </Grid>
                    </Box>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        <Grid container justifyContent="flex-start">
                            <Button onClick={this.props.generate} variant="contained" color="secondary">
                                <DownloadForOfflineIcon />
                                <p className="button">Alarmdepesche generieren</p>
                            </Button>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}/>
                <Grid item xs={12} md={4} >
                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <Grid container justifyContent="center">
                            <Button onClick={this.props.clearAll} variant="outlined" color="primary">
                               <CleaningServicesIcon/>
                                <p className="button">Alle Felder leeren</p>
                            </Button>
                        </Grid>
                    </Box>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        <Grid container justifyContent="flex-end">
                            <Button onClick={this.props.clearAll} variant="outlined" color="primary">
                                <CleaningServicesIcon/>
                                <p className="button">Alle Felder leeren</p>
                            </Button>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>

        </div>;
    }
}