class APIRequest {
    static request(date: string, time: string, short: string, keyword: string, city: string, address: string, caller: string, text: string, vehicleFire: [string,string][], vehicleOther: [string,string][], section:string, object:string) {
        APIRequest.openWindowWithPost("https://backend.alarmgen.doktormeta.de/generator/alarmgenAPI.php", {
            date: date,
            time: time,
            short: short,
            keyword: keyword,
            city: city,
            address: address,
            caller: caller,
            text: text,
            vehicleFire: JSON.stringify(vehicleFire),
            vehicleOther: JSON.stringify(vehicleOther),
            section: section,
            object: object
        });
    }

    private static openWindowWithPost(url: string, data: any) {
        const form = document.createElement("form");
        form.target = "_blank";
        form.method = "POST";
        form.action = url;
        form.style.display = "none";

        for (const key in data) {
            const input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = data[key];
            form.appendChild(input);
        }

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }

}

export default APIRequest;