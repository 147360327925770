import React, {Component} from 'react';
import './InputField.css'
import {TextField} from "@mui/material";

type InputFieldProps = {
    title: string
    max_size: Number
    changeHandler: (e: React.ChangeEvent<any>) => void
    description: string
    value: string
    example?: string

}
type InputFieldState = {
}

export default class InputField extends Component<InputFieldProps, InputFieldState> {

    handleChange(event: any) {
        this.props.changeHandler(event)
    }

    render() {
        let hasExample = this.props.example;
        let completeDescription;

        if (hasExample) {
            completeDescription = <div className="Description">{this.props.description} <br/> Zum Beispiel: {this.props.example}</div>
        }
        else {
            completeDescription = <div className="Description">{this.props.description}</div>
        }

        return <div>
            <TextField id={this.props.title + "-TF"} onChange={this.handleChange.bind(this)} sx={{width: 300}}
                       label={this.props.title} variant="outlined"
                       helperText={this.props.value.length.toString() + "/" + this.props.max_size}
                       value={this.props.value}
                       inputProps={{
                           maxLength: this.props.max_size,
                       }}/>
            {completeDescription}
        </div>;
    }
}